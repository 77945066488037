declare global {
    // eslint-disable-next-line
    interface Window extends WeblabsWindow {}
}

export interface WeblabsWindow {
    renderingWeblabs?: { [key: string]: string };
}

export class Weblab {
    constructor(
        private readonly weblabsWindow: WeblabsWindow,
        private readonly prefix: string,
    ) {}

    isT1 = (): boolean => {
        return this.weblabTreatment() === 't1';
    };

    isT2 = (): boolean => {
        return this.weblabTreatment() === 't2';
    };

    isT3 = (): boolean => {
        return this.weblabTreatment() === 't3';
    };

    isT4 = (): boolean => {
        return this.weblabTreatment() === 't4';
    };

    isT5 = (): boolean => {
        return this.weblabTreatment() === 't5';
    };

    private weblabTreatment = (): string | undefined => {
        return this.weblabsWindow?.renderingWeblabs?.[this.prefix]?.toLowerCase();
    };
}

/*
 * Gating weblab for ADSP video rendering
 * @link  https://weblab.amazon.com/wl/ADPT_SF_ADSP_VIDEO_833419
 */
export const ADPT_SF_ADSP_VIDEO_833419 = () => new Weblab(window, 'ADPT_SF_ADSP_VIDEO_833419');

/*
 * Will increase the amount of errors logged for transparency information missing
 * @link  https://weblab.amazon.com/wl/ADPT_SF_TRANSPARENCY_INFO_MANDATORY_FOR_EU_712921
 */
export const ADPT_SF_TRANSPARENCY_INFO_MANDATORY_FOR_EU = () =>
    new Weblab(window, 'ADPT_SF_TRANSPARENCY_INFO_MANDATORY_FOR_EU_712921');

/*
 * Gonna be used for some general light ads refactorings
 * @link  https://weblab.amazon.com/wl/ADPT_SF_LIGHTADS_REFACTOR_903064
 */
export const ADPT_SF_LIGHTADS_REFACTOR = () => new Weblab(window, 'ADPT_SF_LIGHTADS_REFACTOR_903064');

/*
 * Resize reimplementation using aspect ratio
 * @link  https://weblab.amazon.com/wl/ADPT_SF_RESIZE_ASPECT_RATIO_909226
 */
export const ADPT_SF_RESIZE_ASPECT_RATIO = () => new Weblab(window, 'ADPT_SF_RESIZE_ASPECT_RATIO_909226');

/*
 * Gating weblab for Sponsored Label refactoring
 * @link https://weblab.amazon.com/wl/ADPT_SF_DEFAULT_SPONSORED_LABEL_863363
 */
const ADPT_SF_DEFAULT_SPONSORED_LABEL_863363 = () => new Weblab(window, 'ADPT_SF_DEFAULT_SPONSORED_LABEL_863363');
export const isGPATGeneratedSponsoredLabelEnabled = () => ADPT_SF_DEFAULT_SPONSORED_LABEL_863363().isT1();
