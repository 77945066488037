// TODO: This file should be deleted once placements have become slot agnostic

export const isSparkle = (slotName: string): boolean => {
    return ['mobile-auto-top-advertising-0', 'auto-top-advertising-0'].includes(slotName);
};
/*
 * As part of Sparkle we are deprecating the placementName as a unique identifier
 * This function will use the new unique identifier for the Sparkle placement to begin with
 * Later this function will be deprecated, and renderingId used throughout the project
 */
export type ScopeDiscriminator = { slotName: string; renderingId: string; placementName: string };
/** @deprecated Remove this as placements are migrated to use scopes */
export const getScope = (discriminator: ScopeDiscriminator): string => {
    return isSparkle(discriminator.slotName) ? discriminator.renderingId : discriminator.placementName;
};
