import { HtmlProviderPort, AdResponse } from '../../domain/htmlProviderPort';
import { HTTP_ERROR, REQUEST_START } from '../../../components/counters/AD_FETCH_COUNTERS';
import { fetchGetAd } from '../../../components/aax/aax';
import { EMPTY_RESPONSE, ERROR_RETURNED, PUNT_RETURNED } from '../../../components/events/AD_FETCH_EVENTS';
import { CommonApiImplementation } from '../../CommonApiImplementation';
import * as AD_FETCH_EVENTS from '../../../components/events/AD_FETCH_EVENTS';

export class XspClientHtmlProvider implements HtmlProviderPort {
    constructor(
        private readonly sfApi: CommonApiImplementation,
        private readonly aaxUrl: string,
    ) {}

    async getAd(): Promise<AdResponse> {
        try {
            this.sfApi.countMetric(REQUEST_START, 1);
            this.sfApi.logCsaEvent(AD_FETCH_EVENTS.REQUEST_START);
            const adContent = await fetchGetAd(this.aaxUrl);
            switch (adContent?.status) {
                case 'ok': {
                    return Promise.resolve<AdResponse>({
                        creative: adContent.creative,
                        creativeTemplateName: adContent.creativeTemplateName,
                        instrumentationPixelUrl: adContent.instrPixelURL,
                        impressionPixelUrl: adContent.impPixelURL,
                    });
                }
                case 'punt': {
                    this.sfApi.countMetric(PUNT_RETURNED, 1);
                    this.sfApi.logCsaEvent(AD_FETCH_EVENTS.PUNT_RETURNED);
                    break;
                }
                case 'error': {
                    this.sfApi.countMetric(ERROR_RETURNED, 1);
                    this.sfApi.logCsaEvent(AD_FETCH_EVENTS.ERROR_RETURNED);
                    break;
                }
                default: {
                    this.sfApi.countMetric(EMPTY_RESPONSE, 1);
                    this.sfApi.logCsaEvent(AD_FETCH_EVENTS.EMPTY_RESPONSE);
                    break;
                }
            }
        } catch (err) {
            this.sfApi.countMetric(HTTP_ERROR, 1);
            this.sfApi.logCsaEvent(AD_FETCH_EVENTS.HTTP_ERROR);
            this.sfApi.logError("Couldn't fetch Ad From AAX.", err as Error);
        }

        throw new Error('Could not fetch creative html');
    }
}
