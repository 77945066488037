import { HtmlProviderPort } from './htmlProviderPort';
import { ServerHtmlProvider } from '../outbound/htmlProvider/serverHtmlProvider';
import { XspClientHtmlProvider } from '../outbound/htmlProvider/xspClientHtmlProvider';
import { OrtbClientHtmlProvider } from '../outbound/htmlProvider/ortbClientHtmlProvider';
import { AdDetails } from '../../../@types/adCommon';
import { CommonApiImplementation } from '../CommonApiImplementation';

export const htmlProvider = (adDetails: AdDetails, sfApi: CommonApiImplementation): HtmlProviderPort => {
    if (adDetails.creative.flow === 'SERVER_SIDE') {
        return new ServerHtmlProvider(adDetails.htmlContentEncoded, adDetails.htmlContentEncodedLength);
    } else if (adDetails.creative.flow === 'XSP_CLIENT_SIDE' && adDetails.creative.url) {
        return new XspClientHtmlProvider(sfApi, adDetails.creative.url);
    } else if (adDetails.creative.flow === 'ORTB_CLIENT_SIDE' && adDetails.creative.url) {
        return new OrtbClientHtmlProvider(adDetails.creative.url);
    }

    throw new Error('Wrong creative flow provided');
};
