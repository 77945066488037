import { HtmlProviderPort, AdResponse } from '../../domain/htmlProviderPort';

export class OrtbClientHtmlProvider implements HtmlProviderPort {
    constructor(private readonly url: string) {}

    async getAd(): Promise<AdResponse> {
        return { creative: await this.creative() };
    }

    private creative = async (): Promise<string> => {
        return (await fetch(this.url, { headers: [['Accept', 'text/html']] })).text();
    };
}
