import type { AdDetails } from '../../@types/adCommon';
import type { SafeFrameClient } from '../components/safeFrame';
import { ClientMessageSender } from './clientMessageSender';
import * as AD_LOAD_EVENTS from '../components/events/AD_LOAD_EVENTS';
import { InternalSFClientAPI } from './InternalSFClientAPI';
import type {} from '../host/components/adReporter';
import { RENDER_END, RENDER_START } from '../components/counters/AD_LOAD_COUNTERS';
import type { CommonSupportedCommands } from '../host/CommonSupportedCommands';

/*
 * Helper function to prepare creative template name for tagging
 * 1. Change to lower case
 * 2. Remove all non-alphanumeric characters
 * 3. Use 'unknown' if it does not exist
 */
export const prepareCreativeTemplateNameForTagging = (creativeTemplateName: string): string => {
    if (creativeTemplateName) {
        return creativeTemplateName.toLowerCase().replace(/[^0-9a-z]/g, '');
    }

    return 'unknown';
};

export const tagRenderFlow = (c: InternalSFClientAPI, o: AdDetails, cms: ClientMessageSender) => {
    if (o && o.adCreativeMetaData && Object.keys(o.adCreativeMetaData).length !== 0) {
        const creativeTemplateName = prepareCreativeTemplateNameForTagging(o.adCreativeMetaData.adCreativeTemplateName);
        c.addCsmTag('adrender');
        c.addCsmTag('adrender:safeframe');
        c.addCsmTag('adrender', 'creativetemplatename:' + creativeTemplateName);
        cms.sendMessage<CommonSupportedCommands['addCsaEntity']>('addCsaEntity', {
            adrender: 'true',
            creativeTemplateName: creativeTemplateName,
            isLightAds: 'false',
        });

        if (o.adCreativeMetaData.adCreativeId) {
            c.addCsmTag('adrender', 'creativeid:' + o.adCreativeMetaData.adCreativeId);
            cms.sendMessage<CommonSupportedCommands['addCsaEntity']>('addCsaEntity', {
                creativeId: o.adCreativeMetaData.adCreativeId,
            });
        }

        if (o.adCreativeMetaData.adProgramId) {
            c.addCsmTag('adrender', 'programid:' + o.adCreativeMetaData.adProgramId);
            cms.sendMessage<CommonSupportedCommands['addCsaEntity']>('addCsaEntity', {
                adProgramId: o.adCreativeMetaData.adProgramId,
            });
        }

        if (typeof o.isCardsFlow !== 'undefined') {
            cms.sendMessage<CommonSupportedCommands['addCsaEntity']>('addCsaEntity', {
                isCardsFlow: o.isCardsFlow.toString(),
            });
        }
    } else {
        c.addCsmTag('noadrender');
        cms.sendMessage<CommonSupportedCommands['addCsaEntity']>('addCsaEntity', { adrender: 'false' });
    }
};

export const renderHtml = async (htmlContent: string, client: SafeFrameClient, clientApi: InternalSFClientAPI) => {
    clientApi.countMetric(RENDER_START, 1);
    clientApi.logCsaEvent(AD_LOAD_EVENTS.RENDER_START);
    clientApi.sendAdBarTrace('renderHtmlContentStart', {
        readyState: document.readyState,
    });

    // We add an event listener if needed to track when the replay is all done
    const eventFired = waitUntilCreativeScriptsSyncExecuted();

    // The below conditional checks if an inline script is running AND that document has not
    // finished painting. In this case, document.write should append to the document immediately
    // below that script, as described here: https://www.oreilly.com/library/view/javascript-the-definitive/0596000480/re204.html
    // In all other cases we will replace the contents of the document with the string passed to document.write.

    if (client.renderCompleteTime && document.readyState !== 'complete' && document.currentScript !== null) {
        let currentElement: Element | null = document.currentScript;
        currentElement.insertAdjacentHTML('afterend', htmlContent);

        // Scripts do not execute when added via html string. Need to replay each script after the
        // insertion point (via next sibling). If we used replayAll on the body, it would infinitely
        // loop by replaying the script which called renderHTML in the first place.
        currentElement = currentElement.nextElementSibling;
        while (currentElement !== null) {
            if (currentElement.tagName === 'SCRIPT') {
                replayScriptTag(currentElement as HTMLScriptElement);
            } else {
                replayAllDescendantScriptsSerially(currentElement as HTMLElement);
            }
            currentElement = currentElement.nextElementSibling;
        }
    } else {
        writeHtmlToDocumentRoot(htmlContent);
        addFinishProcessingEvent();
        replayAllDescendantScriptsSerially(document.documentElement);
    }

    client.ensureGlobals();
    await eventFired;
    await waitUntilImagesPartiallyLoadedOrComplete();
    // We record the render complete time as when all the sync scripts have parsed.  We do this because not all scripts will have static images
    // so can't just wait until images are loaded.  This is a judgement call however since some images theoretically could load after the script.
    client.renderCompleteTime = new Date();

    clientApi.countMetric(RENDER_END, 1);
    clientApi.logCsaEvent(AD_LOAD_EVENTS.RENDER_END);
    clientApi.sendAdBarTrace('renderHtmlContentEnd', {
        readyState: document.readyState,
    });
};

const creativeReplayId = 'creativeReplayStatus';
const creativeReplayAttr = 'iscomplete';
export const creativeReplayCompleteEventName = 'creativeReplayComplete';
const fireEvent = `document.dispatchEvent(new CustomEvent("${creativeReplayCompleteEventName}"));`;

// We need to send even when the doc is finished serial processing
const addFinishProcessingEvent = () => {
    document.body.innerHTML += `<script id="${creativeReplayId}">${fireEvent}</script>`;
};

type FiredAttributes = 'iscomplete' | 'creativeLoad' | 'creativedomcontentloaded';

// This is so we can tell from the DOM if the creative has triggered
//  This is useful for UI testing and also interactive debugging
export const markScriptAsFired = (attr: FiredAttributes) =>
    document.getElementById(creativeReplayId)?.setAttribute(attr, 'true');

export const waitUntilCreativeScriptsSyncExecuted = () =>
    new Promise<void>((r, reject) => {
        const el = document.getElementById(creativeReplayId);
        if (el?.getAttribute(creativeReplayAttr) === 'true') {
            r();
        } else {
            document.addEventListener(
                creativeReplayCompleteEventName,
                () => {
                    markScriptAsFired(creativeReplayAttr);
                    r();
                },
                { once: true },
            );
            const reallyLongTimeoutJustToGetRTLAToGenerate = 5000;
            setTimeout(() => {
                if (el?.getAttribute(creativeReplayAttr) !== 'true') {
                    reject(
                        new Error(
                            `Creative script replay not detected within ${reallyLongTimeoutJustToGetRTLAToGenerate} timeout`,
                        ),
                    );
                }
            }, reallyLongTimeoutJustToGetRTLAToGenerate);
        }
    });

export const replayScriptTag = (adScript: HTMLScriptElement): void => {
    const range = document.createRange();
    range.selectNode(adScript);
    const adScriptCopyDoc = range.createContextualFragment(adScript.outerHTML);
    const adScriptCopy = adScriptCopyDoc.firstElementChild as HTMLScriptElement;
    if (adScriptCopy.getAttribute('src')) {
        // https://html.spec.whatwg.org/multipage/scripting.html#attr-script-async
        // Need to explicity force async to false to skip https://html.spec.whatwg.org/multipage/scripting.html#script-processing-model
        // https://hsivonen.fi/script-execution/
        adScriptCopy.async = false;
    } else {
        const scriptText = adScript.innerHTML;
        adScriptCopy.async = false;
        adScriptCopy.setAttribute('src', `data:text/javascript;charset=UTF-8,${encodeURIComponent(scriptText)}`);
    }
    adScript.parentNode?.insertBefore(adScriptCopy, adScript.nextSibling);
    adScript.parentNode?.removeChild(adScript);
};

/**
 * Replaces the contents of the document with the passed HTML string
 * @param htmlContent string of HTML to add
 */
export const writeHtmlToDocumentRoot = (htmlContent: string): void => {
    // setting innerHtml drops the host element's attributes, ie <html lang="en">
    // setting the outerHtml solves this, but setting outerHtml on document.documentElement is forbidden by the spec
    // supporting a target of document.documentElement is a must

    // Funciton fails with strict equality
    // eslint-disable-next-line eqeqeq
    if (document.documentElement == undefined) {
        const html = document.createElement('html');
        document.appendChild(html);
    }
    document.documentElement.innerHTML = htmlContent;

    // options for getting attributes from the html string include DOMParser or regex, but performance is a concern
    // https://developer.mozilla.org/en-US/docs/Web/API/Element/attributes#enumerating_elements_attributes
};

/**
 * Executes all of the descendant inline scripts of the passed element in the order in which they appear
 * via depth first, pre-order DOM tree traversal
 */
export const replayAllDescendantScriptsSerially = (containerElement: HTMLElement): void => {
    // https://html.spec.whatwg.org/multipage/scripting.html#list-of-scripts-that-will-execute-in-order-as-soon-as-possible
    // We cannot use createFragment because it is required to import in the body so we lose the HEAD/BODY/etc. tags
    // So we use InnerHTML which will not execute the scripts but then dynamically recreate the script tags
    // so that they will be executed (as they are not with innerHTML imported tags)
    const adScripts = containerElement.querySelectorAll('script');
    for (const adScript of adScripts) {
        replayScriptTag(adScript);
    }
};

//https://html.spec.whatwg.org/multipage/images.html#img-inc
const imageIsDownloading = (img: HTMLImageElement) => img.naturalWidth > 0 || img.naturalHeight > 0;
const imageIsTrackingPixel = (img: HTMLImageElement) => img.height === 0 || img.width === 0;
const isNotCompleteOrPartiallyLoadedOrTrackingPixel = (img: HTMLImageElement) => {
    !img.complete || imageIsDownloading(img) || imageIsTrackingPixel(img);
};
// This simulates the resource loading requirement of the load event that will be fired
// It is slightly difference in that it only does images, ignores tracking pixels and returns when
// the images are partially available
export async function waitUntilImagesPartiallyLoadedOrComplete(): Promise<void> {
    const images = document.querySelectorAll('img');
    const imagePromises = Array.from(images)
        .filter(isNotCompleteOrPartiallyLoadedOrTrackingPixel)
        .map(
            (img) =>
                new Promise<void>((resolve) => {
                    img.addEventListener(
                        'load',
                        () => {
                            resolve();
                        },
                        { once: true },
                    );
                }),
        );
    await Promise.all(imagePromises);
}
