import { AdResponse, HtmlProviderPort } from '../../domain/htmlProviderPort';
import { utf_8_atob } from '../../../components/util';
import { logError } from '@amzn/apejs-instrumentation/src/metrics/logging';

export class ServerHtmlProvider implements HtmlProviderPort {
    constructor(
        private htmlContentEncoded: string | undefined,
        private htmlContentEncodedLength: number | undefined,
    ) {}

    async getAd(): Promise<AdResponse> {
        return {
            creative: await this.creative(),
        };
    }

    private creative = async (): Promise<string> => {
        if (this.htmlContentEncoded) {
            return this.decodeHtmlContent(this.htmlContentEncoded);
        } else {
            throw new Error('No html content found on a server side fetch');
        }
    };

    private decodeHtmlContent = (htmlContentEncoded: string): string => {
        // TODO Remove the backwards compatible approach
        const htmlContent = this.htmlContentEncodedLength ? htmlContentEncoded : utf_8_atob(htmlContentEncoded);
        if (this.htmlContentEncodedLength && htmlContent.length !== this.htmlContentEncodedLength) {
            logError(
                `Mismatched expected htmlContentEncoded size. Expected ${this.htmlContentEncodedLength} but got ${htmlContent.length}`,
            );
        }
        return htmlContent;
    };
}
